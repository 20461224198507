import Form from './form';
import React, { useState, useEffect } from 'react';
const CustomButton = ({ onClick }) => (
  <button
    onClick={onClick}
    className="justify-center text-white text-center text-xl font-bold leading-7 whitespace-nowrap items-center border shadow-sm bg-orange-500 self-center w-56 max-w-full mt-9 px-4 py-4 rounded border-solid border-black border-opacity-20 no-underline"
  >
    START FREE TRIAL
  </button>
  // <button
  //     onClick={onClick}
  //     className="justify-center text-white text-center text-xl font-bold leading-7 uppercase whitespace-nowrap items-center border shadow-sm bg-red-600 self-center w-[284px] max-w-full mt-8 px-5 py-4 rounded border-solid border-black border-opacity-20 hover:cursor-pointer"
  // >
  //     BOOK your 35% OFFER
  // </button>
);
export default function CircuitHeader({ isGlwp, type, youtubeUrl1, wholeDate }) {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [wholeDates, setWholeDates] = useState(0);

  useEffect(() => {
    console.log(wholeDate, 'whole date')
    const currentDate = new Date();
    const differenceInMilliseconds = wholeDate - currentDate;
    let day = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    let hour = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minute = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    day = Math.max(day, 0);
    hour = Math.max(hour, 0);
    minute = Math.max(minute, 0);
    setDays(day)
    setHours(hour)
    setMinutes(minute)
    setWholeDates(wholeDate)
  }, [wholeDate]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setMinutes(prevMinutes => {
        if (prevMinutes > 0) {
          return prevMinutes - 1;
        } else {
          setHours(prevHours => {
            if (prevHours > 0) {
              setMinutes(59);
              return prevHours - 1;
            } else {
              setDays(prevDays => {
                if (prevDays > 0) {
                  setHours(23);
                  setMinutes(59);
                  return prevDays - 1;
                } else {
                  clearInterval(timerInterval);
                  return 0;
                }
              });
            }
          });
        }
      });
    }, 60000);

    return () => clearInterval(timerInterval);
  }, [days, hours, minutes]);

  return (
    <main className="bg-black self-stretch flex w-full flex-col items-center -mr-5  py-6 max-md:max-w-full">
      <section className="flex w-[1017px] max-w-[90%] flex-col">
        <img
          loading="lazy"
          src="/circuit.png"
          className="aspect-[4.82] object-contain object-center w-[400px] overflow-hidden self-center max-w-full"
          alt="Fitness Image"
        />
        {isGlwp ? (
          <h1 className="justify-center text-orange-500 text-center font-bold leading-9 self-stretch mt-8 max-md:max-w-full">
            THE ONLY GROUP WORKOUT IN THE WORLD ! WHERE CAN LOSE MORE THAN 10% OF YOUR WEIGHT IN JUST 4 WEEKS, WITHOUT DOING CARDIO OR KILLING YOURSELF IN HIGH INTENSITY WORKOUTS, WHILE EATING EVERYTHING YOU WANT !
          </h1>
        ) : (
          <h1 className="justify-center text-orange-500 text-center font-bold leading-9 self-stretch mt-8 max-md:max-w-full">
            THE ONLY GROUP WORKOUT IN THE WORLD ! WHERE CAN LOSE WEIGHT AND BUILD MUSCLES, WITHOUT DOING CARDIO OR KILLING YOURSELF IN HIGH INTENSITY WORKOUTS,
            WHILE EATING EVERYTHING YOU WANT !
          </h1>
        )}


        {isGlwp &&
          (
            <>
              <div className="justify-center items-stretch bg-orange-500 self-stretch flex flex-col mt-6 max-md:max-w-full">
                <div className="justify-center text-zinc-800 text-center text-2xl font-bold leading-9 whitespace-nowrap items-center bg-orange-500 px-5 py-2.5 max-md:max-w-full">
                  AND YOU KNOW WHAT !
                </div>
              </div>
              <div className="justify-center text-white text-center text-10xl-3 font-bold leading-10 self-center mt-3 max-md:max-w-full">
                IF YOU DO NOT DO IT, YOU GET ALL YOUR MONEY BACK !
              </div>
            </>
          )
        }

        {!isGlwp &&
          (
            <a
              href="#"
              className="justify-center text-stone-300 text-center text-2xl leading-9 self-center  mt-9 max-md:max-w-full no-underline hover:underline"
            >
              FINALLY THE VIBES & COMMUNITY OF THE CLASSES IN THE GYM
            </a>
          )
        }


        <div className="justify-center items-stretch bg-black self-center flex w-[550px] h-[300px] max-w-full mt-12 max-md:mt-10">
          {/* <div className="bg-black flex items-stretch" style={{ height: "100%" }}> */}
          <iframe
            src={youtubeUrl1}
            allowFullScreen=""
            wmode="opaque"
            title="Circuit"
            id="fitvid646651"
            style={{ width: "100%", height: "100%" }}
          />
          {/* </div> */}
        </div>
        <div >
          <h2 className="justify-center text-stone-300 text-center text-xl font-bold leading-6 self-center  mt-9">
            Gym experience Like Never Before.
          </h2>
          <h2 className="justify-center text-stone-300 text-center text-xl font-bold leading-6 self-center  mt-2">
            Now training in the gym couldn't be
          </h2>
          <h2 className="justify-center text-stone-300 text-center text-xl font-bold leading-6 self-center mt-2">
            more Addictive, Fun & Full of energy
          </h2>
        </div>
        <div className="self-center flex  max-w-full items-stretch justify-between mt-5 max-md:mt-10 space-x-5">
          <div className="flex basis-[0%] flex-col items-stretch">
            <div className="flex items-stretch space-x-5">
              <div className="justify-center text-orange-500 text-center text-2xl font-bold leading-10">
                {days > 10 ? days : `0${days || 0}`}
              </div>
              <div className="justify-center text-orange-500 text-center text-11xl font-bold leading-10 whitespace-nowrap">
                :
              </div>
            </div>
            <div className=" text-orange-500  text-left text-xs leading-4 uppercase whitespace-nowrap -ml-1 mt-4">
              Days
            </div>
          </div>
          <div className="flex basis-[0%] flex-col items-stretch">
            <div className="flex items-stretch space-x-5">
              <div className="justify-center text-orange-500 text-center text-2xl font-bold leading-10">
                {hours > 10 ? hours : `0${hours || 0}`}
              </div>
              <div className="justify-center text-orange-500 text-center text-11xl font-bold leading-10 whitespace-nowrap">
                :
              </div>
            </div>
            <div className="  text-orange-500  text-left text-xs leading-4 uppercase whitespace-nowrap -ml-2 mt-4">
              Hours
            </div>
          </div>
          <div className="flex basis-[0%] flex-col items-stretch">
            <div className="flex items-stretch space-x-5">
              <div className="justify-center text-orange-500 text-center text-2xl font-bold leading-10">
                {minutes > 10 ? minutes : `0${minutes || 0}`}
              </div>
              <div className="justify-center text-orange-500 text-center text-11xl font-bold leading-10 whitespace-nowrap">
              </div>
            </div>
            <div className="  text-orange-500  text-left text-xs leading-4 uppercase whitespace-nowrap -ml-2 mt-4">
              Minutes
            </div>
          </div>
        </div>
        <Form type={type} buttonComponent={(toggleModal) => <CustomButton onClick={toggleModal} />} />
        {/* <a
          href="#"
          className="justify-center text-white text-center text-xl font-bold leading-7 whitespace-nowrap items-center border shadow-sm bg-orange-500 self-center w-56 max-w-full mt-9 px-4 py-4 rounded border-solid border-black border-opacity-20 no-underline"
        >
          START FREE TRIAL
        </a> */}

      </section>
    </main >
  );
}