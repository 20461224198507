import React, { useState, useEffect } from 'react';

export default function Say({ youtubeUrl3, youtubeUrl4, youtubeUrl5, youtubeUrl6, wholeDate }) {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [wholeDates, setWholeDates] = useState(0);
    useEffect(() => {
        console.log(wholeDate, 'whole date')
        const currentDate = new Date();
        const differenceInMilliseconds = wholeDate - currentDate;
        let day = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        let hour = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minute = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        day = Math.max(day, 0);
        hour = Math.max(hour, 0);
        minute = Math.max(minute, 0);
        setDays(day)
        setHours(hour)
        setMinutes(minute)
        setWholeDates(wholeDate)
    }, [wholeDate]);

    useEffect(() => {
        const timerInterval = setInterval(() => {
            setMinutes(prevMinutes => {
                if (prevMinutes > 0) {
                    return prevMinutes - 1;
                } else {
                    setHours(prevHours => {
                        if (prevHours > 0) {
                            setMinutes(59);
                            return prevHours - 1;
                        } else {
                            setDays(prevDays => {
                                if (prevDays > 0) {
                                    setHours(23);
                                    setMinutes(59);
                                    return prevDays - 1;
                                } else {
                                    clearInterval(timerInterval);
                                    return 0;
                                }
                            });
                        }
                    });
                }
            });
        }, 60000);

        return () => clearInterval(timerInterval);
    }, [days, hours, minutes]);
    return (
        <div className="justify-center items-center bg-gray-200 flex flex-col px-5">
            <div className="flex w-[1100px] max-w-full flex-col mt-12 mb-10 max-md:mt-10">
                <div className="justify-center text-black text-center text-4xl font-bold leading-[52px] self-center  max-md:max-w-full">
                    WHAT OUR ATHELETS HAVE TO SAY ABOUT US
                </div>
                <div className="self-stretch mt-14 max-md:max-w-full max-md:mt-10">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-3/12 max-md:w-full max-md:ml-0">
                            <div className="justify-center items-stretch bg-black flex grow flex-col w-full mx-auto max-md:mt-10">
                                <div className="bg-black flex flex-col items-stretch">
                                    <iframe
                                        src={youtubeUrl3}
                                        frameborder="0" allowfullscreen="" wmode="opaque" id="fitvid911623"></iframe>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
                            <div className="justify-center items-stretch bg-black flex grow flex-col w-full mx-auto max-md:mt-10">
                                <iframe
                                    src={youtubeUrl4}
                                    frameborder="0" allowfullscreen="" wmode="opaque" id="fitvid459963"></iframe>
                            </div>
                        </div>
                        <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
                            <div className="justify-center items-stretch bg-black flex grow flex-col w-full mx-auto max-md:mt-10">
                                <iframe
                                    src={youtubeUrl5}
                                    frameborder="0" allowfullscreen="" wmode="opaque" id="fitvid812561"></iframe>
                            </div>
                        </div>
                        <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
                            <div className="justify-center items-stretch bg-black flex grow flex-col w-full mx-auto max-md:mt-10">
                                <iframe
                                    src={youtubeUrl6}
                                    frameborder="0" allowfullscreen="" wmode="opaque" id="fitvid590821"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="self-center flex w-[206px] max-w-full justify-between gap-5 mt-16 max-md:justify-center max-md:mt-10">
                    <div className="justify-center text-orange-500 text-center text-2xl font-bold leading-10 self-stretch">
                        {days > 10 ? days : `0${days || 0}`}
                        <br />
                        <div className="justify-center text-black text-opacity-40 text-center text-xs leading-4 uppercase">
                            Day
                        </div>
                    </div>
                    <div className="justify-center text-orange-500 text-center text-11xl font-bold leading-10">
                        :
                    </div>
                    <div className="justify-center text-orange-500 text-center text-2xl font-bold leading-10 self-stretch">
                        {hours > 10 ? hours : `0${hours || 0}`}
                        <br />
                        <div className="justify-center text-black text-opacity-40 text-center text-xs leading-4 uppercase">
                            Hour
                        </div>
                    </div>
                    <div className="justify-center text-orange-500 text-center text-11xl font-bold leading-10">
                        :
                    </div>
                    <div className="justify-center text-orange-500 text-center text-2xl font-bold leading-10 self-stretch whitespace-nowrap">
                        {minutes > 10 ? minutes : `0${minutes || 0}`}
                        <br />
                        <div className="justify-center text-black text-opacity-40 text-center text-xs leading-4 uppercase whitespace-nowrap">
                            Minute
                        </div>
                    </div>
                </div>
                <div className="justify-center text-white text-center  font-bold leading-7 uppercase whitespace-nowrap items-center border shadow-sm bg-orange-500 self-center w-[318px] max-w-full mt-5 px-5 py-4 rounded border-solid border-black border-opacity-20 sm:text-xl">
                    BOOK YOUR SPOT FOR FREE
                </div>
            </div>
        </div>
    );
}


