import Form from './form';
import React, { useState, useEffect } from 'react';
const CustomButton = ({ onClick }) => (
    <button
        onClick={onClick}
        className="justify-center cursor-pointer text-white text-center  font-bold leading-7 uppercase whitespace-nowrap items-center border shadow-sm bg-orange-500   mt-3 px-1 py-3 rounded border-solid border-black border-opacity-20 sm:text-xl sm:w-[330px] max-md:mt-10"
    >
        BOOK YOUR SPOT FOR FREE
    </button>
);
function TimerComponent({ type, wholeDate }) {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [wholeDates, setWholeDates] = useState(0);

    useEffect(() => {
        console.log(wholeDate, 'whole date')
        const currentDate = new Date();
        const differenceInMilliseconds = wholeDate - currentDate;
        let day = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        let hour = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minute = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        day = Math.max(day, 0);
        hour = Math.max(hour, 0);
        minute = Math.max(minute, 0);
        setDays(day)
        setHours(hour)
        setMinutes(minute)
        setWholeDates(wholeDate)
    }, [wholeDate]);

    useEffect(() => {
        const timerInterval = setInterval(() => {
            setMinutes(prevMinutes => {
                if (prevMinutes > 0) {
                    return prevMinutes - 1;
                } else {
                    setHours(prevHours => {
                        if (prevHours > 0) {
                            setMinutes(59);
                            return prevHours - 1;
                        } else {
                            setDays(prevDays => {
                                if (prevDays > 0) {
                                    setHours(23);
                                    setMinutes(59);
                                    return prevDays - 1;
                                } else {
                                    clearInterval(timerInterval);
                                    return 0;
                                }
                            });
                        }
                    });
                }
            });
        }, 60000);

        return () => clearInterval(timerInterval);
    }, [days, hours, minutes]);
    return (
        <div className="bg-zinc-600 flex flex-col items-center px-5">
            <div className="flex w-[310px] max-w-full flex-col items-stretch mt-5 mb-5">
                <div className="self-center flex w-[206px] max-w-full items-stretch justify-between gap-5  max-md:justify-center">
                    <div className="justify-center text-orange-500 text-center text-2xl font-bold leading-10 "> {days > 10 ? days : `0${days || 0}`}

                        <br />
                        <div className="text-justify-center text-black text-opacity-40 text-center text-xs leading-4 uppercase "> Day </div>
                    </div>
                    <div className="justify-center text-orange-500 text-center text-11xl font-bold leading-10"> : </div>
                    <div className="justify-center text-orange-500 text-center text-2xl font-bold leading-10">    {hours > 10 ? hours : `0${hours || 0}`}

                        <br />
                        <div className="justify-center text-black text-opacity-40 text-center text-xs leading-4 uppercase "> Hour </div> </div>
                    <div className="justify-center text-orange-500 text-center text-11xl font-bold leading-10"> : </div>
                    <div className="justify-center text-orange-500 text-center text-2xl font-bold leading-10 whitespace-nowrap">   {minutes > 10 ? minutes : `0${minutes || 0}`}

                        <br />
                        <div className="justify-center text-black text-opacity-40 text-center text-xs leading-4 uppercase whitespace-nowrap "> Minute </div> </div>
                </div>
                <Form type={type} buttonComponent={(toggleModal) => <CustomButton onClick={toggleModal} />} />
            </div>
        </div>
    );
}

export default TimerComponent;